import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../services/users/user.service";
import { PlanService } from "src/app/services/planService/plan.service";
import Swal from "sweetalert2";
import { AngularFireStorage } from "@angular/fire/storage";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMinutesService } from "src/app/services/service-minutes/service-minutes.service";
import { Task } from '../../../models/task';
import { EditTimetaskComponent } from "src/app/components/edit-timetask/edit-timetask.component";
import { AddAditionalNumbersComponent } from "src/app/components/add-aditional-numbers/add-aditional-numbers.component";
import { UserData } from "src/app/models/user";
import { Plan } from "src/app/models/plan";
import { CompanyService } from "src/app/services/company/company.service";
import { ContractsService } from "src/app/services/contracts/contracts.service";
import { ProspectsService } from "src/app/services/prospects/prospects.service";
import { Prospect } from "src/app/models/prospects";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-company-form",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class CompanyFormComponent implements OnInit {
  [x: string]: any;
  addForm: FormGroup;
  public users: UserData[] = [];
  public tasks: Task[] = []
  public file: any = [];
  urlPreviweImg: any;
  showContract = false;
  showPage = false;
  showEdits = false;
  nombreE: string;
  urlDefaultImg =
    "https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/logo-default.png?alt=media&token=31c837f0-867c-431f-8778-4b45ba65f926";
  public planClient = ""
  public actualPlans: Plan[] = []
  public config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    acceptedFiles: ".pdf, .jpg, .jpeg, .png"
  };
  public profileImg = []
  public showNewPrice = false;
  public companyData: Prospect;
  public contractInfo = { "status": 0, "nextRenovationDate": new Date(), "_id": '' };
  public fileToSave: any;
  public userType = "";
  public tags: string[] = []
  public tagField = new FormControl('')
  public isEdit = false
  public showError = false
  private fielError = ""
  public commercialTurn: any[] = [
    { value: "Profesional", viewValue: "Profesional" },
    { value: "Comercial", viewValue: "Comercial" },
    { value: "Medico", viewValue: "Medico" },
    { value: "Otro", viewValue: "Otro" },
  ]
  public promotion: any[] = [
    { value: "N/A", viewValue: "N/A" },
    { value: "Otra", viewValue: "Otra" },
  ]
  public sex: any[] = [
    { value: "Masculino", viewValue: "Masculino" },
    { value: "Femenino", viewValue: "Femenino" },
  ]
  public origin: any[] = [
    { value: "Facebook", viewValue: "Facebook" },
    { value: "Google", viewValue: "Google" },
    { value: "BNI", viewValue: "BNI" },
    { value: "Recomendado", viewValue: "Recomendado" },
    { value: "Otro", viewValue: "Otro" },
  ]
  public cities: any[] = [
    { value: "Aguascalientes", viewValue: "Aguascalientes" },
    { value: "Baja California", viewValue: "Baja California" },
    { value: "Baja California Sur", viewValue: "Baja California Sur" },
    { value: "Campeche", viewValue: "Campeche" },
    { value: "Coahuila", viewValue: "Coahuila" },
    { value: "Chiapas", viewValue: "Chiapas" },
    { value: "Colima", viewValue: "Colima" },
    { value: "Chihuahua", viewValue: "Chihuahua" },
    { value: "Durango", viewValue: "Durango" },
    { value: "Estado de México", viewValue: "Estado de México" },
    { value: "Guanajuato", viewValue: "Guanajuato" },
    { value: "Guerrero", viewValue: "Guerrero" },
    { value: "Hidalgo", viewValue: "Hidalgo" },
    { value: "Jalisco", viewValue: "Jalisco" },
    { value: "Ciudad de México", viewValue: "Ciudad de México" },
    { value: "Michoacán", viewValue: "Michoacán" },
    { value: "Morelos", viewValue: "Morelos" },
    { value: "Nayarit", viewValue: "Nayarit" },
    { value: "Nuevo León", viewValue: "Nuevo León" },
    { value: "Oaxaca", viewValue: "Oaxaca" },
    { value: "Puebla", viewValue: "Puebla" },
    { value: "Querétaro", viewValue: "Querétaro" },
    { value: "Quintana Roo", viewValue: "Quintana Roo" },
    { value: "San Luis Potosí", viewValue: "San Luis Potosí" },
    { value: "Sinaloa", viewValue: "Sinaloa" },
    { value: "Sonora", viewValue: "Sonora" },
    { value: "Tabasco", viewValue: "Tabasco" },
    { value: "Tamaulipas", viewValue: "Tamaulipas" },
    { value: "Tlaxcala", viewValue: "Tlaxcala" },
    { value: "Veracruz", viewValue: "Veracruz" },
    { value: "Yucatán", viewValue: "Yucatán" },
    { value: "Zacatecas", viewValue: "Zacatecas" },
  ]
  public isLoading = true;

  constructor(
    public formBuilder: FormBuilder,
    private user_service: UserService,
    private planService: PlanService,
    private storage: AngularFireStorage,
    private router: Router,
    private taskService: ServiceMinutesService,
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private contractService: ContractsService,
    private prospectService: ProspectsService,
    private dialog: MatDialog
  ) {
    this.addForm = this.formBuilder.group({
      profileImg: [""],
      nombre: ["", Validators.required],
      nombreCliente: ["", Validators.required],
      ejecutivo: [[], Validators.required],
      telefono: ["", Validators.required],
      direccion: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      giroComercial: ["", Validators.required],
      plan: ["", Validators.required],
      planPrice: ["", Validators.pattern('[0-9]*')],
      fiscalAddress: ["", Validators.required],
      contratoDate: ["", Validators.required],
      tareasRecurrentes: [[], Validators.required],
      profession: ["", Validators.required],
      prefix: ["", Validators.required],
      drive: ["", Validators.required],
      tag: ["", Validators.required],
      startHourWork: ["", Validators.required],
      endHourWork: ["", Validators.required],
      specialSchedule: [false, Validators.required],
      gender: [""],
      age: [''],
      city: ['', Validators.required],
      origin: ['', Validators.required],
      invoice: [''],
      completeData: ['', Validators.required],
      assistant: [[], Validators.required]
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  close() {
    this.router.navigate(['/clients'])
  }

  async ngOnInit() {
    await this.getData()
    var companyId = this.activeRoute.snapshot.params['id'] ?? ''
    if (companyId != '') {
      this.isEdit = true
      this.companyData = await this.getcompanyData(companyId)
      var findComercialTurn = this.commercialTurn.filter((speciality) => speciality.value == this.companyData.giroComercial)
      if (findComercialTurn.length == 0) {
        this.companyData.giroComercial = "Otro"
      }
      this.contractInfo = await this.getContract(this.companyData.uid);
      this.urlPreviweImg = this.companyData.profileImg;
      var starthour = { hour: 0, minute: 0, second: 0 };
      var endhour = { hour: 0, minute: 0, second: 0 };
      if (this.companyData.startHourWork == undefined) {
        starthour = { hour: 9, minute: 0, second: 0 };
        endhour = { hour: 18, minute: 0, second: 0 };
      } else {
        starthour = {
          hour: this.companyData.startHourWork.hour,
          minute: this.companyData.startHourWork.minute,
          second: this.companyData.startHourWork.second
        };
        endhour = {
          hour: this.companyData.endHourWork.hour,
          minute: this.companyData.endHourWork.minute,
          second: this.companyData.endHourWork.second
        };
      }

      this.companyData.startHourWork = starthour
      this.companyData.endHourWork = endhour

      var comunTask = this.companyData.tareasRecurrentes || []
      var taskList = []
      if (Array.isArray(comunTask)) {
        comunTask.forEach((taskAsing: Task) => {
          var findtask = this.tasks.filter(activity => activity.title === taskAsing.title)
          if (findtask.length > 0) {
            taskList.push(findtask[0]._id)
          }
        })
      }
      this.addForm.patchValue(this.companyData)
      this.addForm.controls.tareasRecurrentes.setValue(taskList)
      this.tags = this.companyData.comercialTags ?? []
    }
    this.isLoading = false
  }

  async getData() {
    this.users = await this.getUsers();
    this.tasks = await this.getTask();
    this.actualPlans = await this.getPlans()
    this.userType = localStorage.getItem("currentUser")
  }

  async getcompanyData(companyId: string) {
    try {
      var response: any = await this.prospectService.getProspectById(companyId)
      return response
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al obtener la informacion de la empresa",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async getPlans() {
    try {
      var reports = await this.planService.getPlans();
      return reports.data
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al obtener los planes",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async getContract(id: string) {
    try {
      var contract = await this.contractService.find(id)
      return contract.data
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al obtener información del contrato",
        icon: "error",
        text: "Si el error persiste, favor de reportarlo"
      });
    }
  }

  async setFile(event: any) {
    this.fileToSave = {
      "url": event.url,
      "extension": event.extension,
      "nextRenovationDate": event.nextRenovationDate,
      "status": event.status
    }
  }

  async addContractDoc(event: any) {
    var formData = this.addForm.value;
    switch (event.type) {
      case 1:
        delete event.type;
        formData['fileFiscal'] = event
        break;
      case 2:
        delete event.type;
        formData['fileContract'] = event
        break;
      case 3:
        delete event.type;
        formData['fileInvoice'] = event
        break;
      case 4:
        delete event.type;
        formData['fileAgreement'] = event
        break;
      case 5:
        delete event.type;
        formData['filePay'] = event
        break;

      default:
        break;
    }
  }

  async getUsers() {
    try {
      var response = await this.user_service.getAllUsers();
      return response.data
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al obtener los agentes",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async getTask() {
    try {
      return await this.taskService.getTask()
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al obtener las tareas",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  saveFile(img) {
    this.file.push(img.target.files[0])
    const reader = new FileReader();
    reader.onload = (e) => (this.urlPreviweImg = reader.result);
    reader.readAsDataURL(this.file[0]);
  }

  private formValidate(obj) {
    const invalidControls: string[] = [];
    Object.keys(obj.controls).forEach((key) => {
      if (obj.controls[key].invalid) {
        invalidControls.push(key);
      }
    });
    return invalidControls.length > 0;
  }

  onlyNums(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/\D/g, '')
  }

  async submit() {
    if (!this.showNewPrice) {
      this.addForm.removeControl("planPrice")
    }
    const form = this.addForm.value
    var taskSelect = form.tareasRecurrentes
    if (this.file.length != 0) {
      form.profileImg = await this.uploadFiles(this.file[0])
    }
    if (this.formValidate(this.addForm)) {
      this.addForm.markAllAsTouched()
      return Swal.fire({
        title: "Todos los campos son obligatorios",
        text: "Favor de revisar que todos los campos esten llenos: " + this.fielError,
        icon: "error",
      });
    }
    var comunTask = []
    if (this.isEdit) {
      comunTask = this.companyData.tareasRecurrentes
    } else {
      comunTask = form.tareasRecurrentes
    }
    var newTask = []
    var editTask = []
    if (typeof comunTask == 'object') {
      for await (const task of taskSelect) {
        var findtask = this.tasks.filter(activity => activity._id === task)
        var asingsTasks = comunTask.filter(element => element.title === findtask[0].title)
        if (asingsTasks.length == 0) {
          newTask.push(findtask[0])
        }
        else {
          editTask.push(asingsTasks[0])
        }
      }
      comunTask = []
      comunTask = editTask.concat(newTask);
    }
    else {
      for await (const task of taskSelect) {
        var findtask = this.tasks.filter(activity => activity._id === task)
        newTask.push(findtask[0])
      }
      comunTask = newTask
    }
    form.comercialTags = this.tags
    if (this.userType == 'administrador') {
      var next = await Swal.fire({
        title: '¿Desea modificar el tiempo de las tareas seleccionadas al cliente?',
        text: 'El tiempo ya está establecido a las tareas, una vez aceptado se asignará con el correspondiente',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Modificar",
        showDenyButton: true,
        denyButtonText: "No cambiar",
        cancelButtonColor: "#6a6a6a",
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#EE6500'
      })
      if (next.isConfirmed) {
        this.isLoading = true
        form.editTasks = comunTask
        const dialogResult = await this.dialog.open(EditTimetaskComponent, { data: form, height: "60vh" }).beforeClosed().toPromise()
        if (dialogResult != undefined) {
          var formTask = dialogResult.form;
          form.tareasRecurrentes = formTask
          //Validacion para cargar la imagen
          //Validacion para el form
          if (this.isEdit) {
            await this.companyService.updateCompany(form, this.companyData.uid);
            if (this.fileToSave != undefined || this.fileToSave != null) {
              var object = {
                "url": this.fileToSave.url,
                "extension": this.fileToSave.extension,
                "file_date": new Date().getTime(),
                "title": "Comprobante de pago " + (new Date().getMonth() + 1) + " / " + new Date().getFullYear(),
                "nextRenovationDate": this.fileToSave.nextRenovationDate ?? new Date().setHours(0, 0, 0, 0),
                "status": this.fileToSave.status
              }
              await this.contractService.update(this.contractInfo._id, object)
            }
          } else {
            await this.companyService.addCompany(form)
          }
          this.isLoading = false
          await Swal.fire({
            position: "center",
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1000,
          });
          this.router.navigate(['clients'])
        }
      }
      if (next.isDenied) {
        this.isLoading = true
        form.tareasRecurrentes = comunTask
        try {
          //Cargar la imagen de perfil
          if (this.file.length != 0) {
            form.profileImg = await this.uploadFiles(this.file[0])
          }
          if (this.isEdit) {
            await this.companyService.updateCompany(form, this.companyData.uid);
            if (this.fileToSave != undefined || this.fileToSave != null) {
              var object = {
                "url": this.fileToSave.url,
                "extension": this.fileToSave.extension,
                "file_date": new Date().getTime(),
                "title": "Comprobante de pago " + new Date().getMonth() + 1 + " / " + new Date().getFullYear(),
                "nextRenovationDate": this.fileToSave.nextRenovationDate ?? new Date().setHours(0, 0, 0, 0),
                "status": this.fileToSave.status
              }
              await this.contractService.update(this.contractInfo._id, object)
            }

          } else {
            await this.companyService.addCompany(form)
          }
          await Swal.fire({
            position: "center",
            icon: "success",
            title: "Datos actualizados",
            showConfirmButton: false,
            timer: 1000,
          });
          this.router.navigate(['clients'])
        } catch (error) {
          if (error.error.code == "auth/invalid-password") {
            return Swal.fire({
              title: "Ocurrió un error al actualizar el cliente",
              text: error.error.message,
              icon: 'error'
            })
          }
          if (error.error.code == "auth/email-already-exists") {
            return Swal.fire({
              title: "Ocurrió un error al actualizar el cliente",
              text: error.error.message,
              icon: 'error'
            })
          }
          Swal.fire({
            title: "Ocurrió un error al actualizar el cliente",
            text: "Si el problema persiste, favor de reportar el error",
            icon: 'error'
          })
        }
      }
    } else if (this.fileToSave != undefined || this.fileToSave != null) {
      this.isLoading = true
      try {
        var object = {
          "url": this.fileToSave.url,
          "extension": this.fileToSave.extension,
          "file_date": new Date().getTime(),
          "title": "Comprobante de pago " + new Date().getMonth() + 1 + " / " + new Date().getFullYear(),
          "nextRenovationDate": this.fileToSave.nextRenovationDate ?? new Date().setHours(0, 0, 0, 0),
          "status": this.fileToSave.status
        }
        await this.contractService.update(this.contractInfo._id, object)
        this.isLoading = false
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Datos actualizados",
          showConfirmButton: false,
          timer: 1000,
        });
        this.router.navigate(['clients'])
      } catch (error) {
        Swal.fire({
          title: "Ocurrió un error al actualizar el cliente",
          text: "Si el problema persiste, favor de reportar el error",
          icon: 'error'
        })
      }
    }
    else {
      await Swal.fire({
        position: "center",
        icon: "info",
        title: "No hay datos a actualizar",
        showConfirmButton: false,
        timer: 1000,
      });
      this.router.navigate(['clients'])
    }
  }

  async uploadFiles(file: any) {
    try {
      let name = Math.random().toString(36).substring(2);
      let refUrl = "companies/ProfilePhotos/" + name;
      let ref = this.storage.ref(refUrl);
      await this.storage.upload(refUrl, file).snapshotChanges().toPromise();
      var downloadURL = await ref.getDownloadURL().toPromise();
      return downloadURL
    } catch (error) {
      console.log(error);

    }
  }

  async addAditionalNumbers() {
    try {
      this.dialog.open(AddAditionalNumbersComponent, { data: this.companyData, height: '50vh' }).beforeClosed().subscribe(action => {
        this.ngOnInit()
      });
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error editar los números",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  validURL() {
    if (this.addForm.value.drive != null)
      return this.addForm.value.drive.includes("http");
    return true;
  }

  addTag() {
    const typeOf = this.addForm.value.giroComercial
    if (typeOf == '') {
      this.errorInComercial = true
      return
    }
    if (this.tagField.value == "") {
      return
    }
    this.tags.push(this.tagField.value)
    this.tagField.setValue('')
  }

  toggle(event: boolean) {
    this.showNewPrice = event
    var selectedPlan = this.addForm.value.plan
    var userPlan = this.actualPlans.filter(Planes => Planes.title == selectedPlan);
    this.addForm.controls.planPrice.setValue(userPlan[0].precio)
  }

  // Validaciones
  fieldRequired(field: string) {
    return this.addForm.get(field)?.hasError('required') && this.addForm.get(field)?.touched || (this.showError && this.addForm.get(field)?.untouched)
  }
  fieldMin(field: string) {
    return this.addForm.controls[field].hasError('min') && this.addForm.controls[field].touched
  }
  fieldMinLength(field: string) {
    return this.addForm.controls[field].hasError('minlength') && this.addForm.controls[field].touched
  }
  fieldPattern(field: string) {
    return this.addForm.controls[field].hasError('pattern') && this.addForm.controls[field].touched
  }
}

