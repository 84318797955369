<div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
<div class="row" style="margin-top: -25px">
  <div class="col-md-3 sidebar-client">
    <div class="row">
      <div class="col-md-12 img-company">
        <img class="imgPreview" [src]="dataCompany?.profileImg" alt="logo image" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center mt-2 mb-2">
        <h3>{{ name_company }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ul class="info-left">
          <li class="title"><span>Cliente</span></li>
          <li class="info">
            <span>{{ dataCompany?.nombreCliente }}</span>
          </li>
          <li class="title"><span>Email</span></li>
          <li class="info">
            <span style="text-transform: lowercase">{{ dataCompany?.email }}</span>
          </li>
          <li class="title"><span>Plan contratado</span></li>
          <li class="info">
            <span> {{dataCompany?.plan}} </span>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <ul class="info-left">
          <li class="title"><span>Número Asignado</span></li>
          <li class="info">
            <span>{{ dataCompany?.telefono }}</span>
          </li>
          <li class="title"><span>Giro Comercial</span></li>
          <li class="info">
            <span>{{ dataCompany?.giroComercial }}</span>
          </li>
          <li class="title"><span>Prefijo</span></li>
          <li class="info">
            <span>{{ dataCompany?.prefix }}</span>
          </li>
        </ul>
      </div>
      <div class="col-md-12">
        <ul class="info-left">
          <li class="title"><span>Dirección</span></li>
          <li class="info">
            <span style="text-transform: lowercase">{{dataCompany?.direccion}} </span>
          </li>
          <li class="title"><span>Fecha de corte</span></li>
          <li class="info">
            <span style="text-transform: lowercase">{{contactInfo?.nextRenovationDate | date}} </span>
          </li>
          <li class="title"><span>Estado del contrato</span></li>
          <li class="info">
            <span>{{contactInfo?.status
              == 1 ?'Pagado':contactInfo?.status == 2 ? "Pendiente por pagar": "Pago atrasado" }} </span>
          </li>
        </ul>
      </div>
      <div class="col-md-12">
        <ul class="info-left">
          <li class="title"><span>Link a drive</span></li>
          <li class="info url">
            <span>
              <a href="{{ dataCompany?.drive  }}" target="_blank" style="text-transform: none;">{{
                dataCompany?.drive }}</a>
            </span>
          </li>
        </ul>
      </div>

      <div class="col-md-12">
        <ul class="info-left">
          <li class="title"><span>Tareas recurrentes</span></li>
          <li class="info" *ngFor="let task of dataCompany?.tareasRecurrentes">
            <span>{{task.title}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="callEnd">
      <div class="col-md-12">
        <div class="toast-call">
          <strong>
            <h3>Llamada finalizada !</h3>
          </strong>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isOnCall == true">
      <div class="col-md-12">
        <nb-card status="success">
          <nb-card-header class="text-center">Llamada en curso</nb-card-header>
          <nb-card-body>
            <div *ngIf="editableContact == true">
              <form [formGroup]="contactInfoForm">
                <div class="form-group row">
                  <div class="col-md-12">
                    <label style="font-size: 12px">NOMBRE: </label>
                    <input type="text" nbInput fullWidth fieldSize="medium" formControlName="name" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label style="font-size: 12px">EMAIL: </label>
                    <input type="text" nbInput fullWidth fieldSize="medium" formControlName="email" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label style="font-size: 12px; text-transform: uppercase">Teléfono</label>
                    <input type="number" nbInput fullWidth fieldSize="medium" formControlName="phone" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label style="font-size: 12px; text-transform: uppercase">Comentarios</label>
                    <textarea formControlName="comments" nbInput fullWidth></textarea>
                  </div>
                </div>

                <div class="form-group row text-center">
                  <div class="col-md-6">
                    <button nbButton size="small" status="danger" (click)="editContactInfo(false)">
                      Cancelar
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button nbButton size="small" status="warning" (click)="saveNewInfoContact()">
                      <nb-icon icon="save-outline"></nb-icon>Guardar
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="editableContact == false">
              <ul class="info-left">
                <li>
                  Nombre: <strong>{{ contactInfo.nombre }}</strong>
                </li>
                <li>
                  Email: <strong>{{ contactInfo.email }}</strong>
                </li>
                <li>
                  Teléfono: <strong>{{ contactInfo.telefono }}</strong>
                </li>
                <li>
                  Comentarios: <strong>{{ contactInfo.comments }}</strong>
                </li>
                <li class="text-center">
                  <button nbButton size="small" status="warning" (click)="editContactInfo(true)">
                    <nb-icon icon="edit-2-outline"></nb-icon>Editar contacto
                  </button>
                </li>
              </ul>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-12 text-center">
        <button nbButton status="danger" shape="round" (click)="closeCall()">
          <nb-icon icon="phone-off-outline" style="height: 2.2rem; width: 2.2rem"></nb-icon>
          Colgar
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-9">
    <div class="card">
      <mat-tab-group>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">event_note</mat-icon>
            <span>Agenda</span>
          </ng-template>
          <app-schedule [listOfEvents]="events" (eventInit)="getEvents(dataCompany.uid)"></app-schedule>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">person</mat-icon>
            <span>{{dataCompany?.specialSchedule ? 'Pacientes' :'Clientes'}} </span>
          </ng-template>
          <ng-container *ngIf="!dataCompany?.specialSchedule">
            <app-clients></app-clients>
          </ng-container>
          <ng-container *ngIf="dataCompany?.specialSchedule">
            <app-patients></app-patients>
          </ng-container>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">message</mat-icon>
            <span>Recados</span>
          </ng-template>
          <app-recados></app-recados>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">info</mat-icon>
            <span>Información</span>
          </ng-template>
          <app-info-client></app-info-client>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">list</mat-icon>
            <span>Bitacora</span>
          </ng-template>
          <app-bitacora></app-bitacora>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">notifications</mat-icon>
            <span [matBadge]="pendingsClient?.length" matBadgeOverlap="false">Pendientes</span>
          </ng-template>
          <div class="app-component" *ngIf="pendingsClient">
            <app-pendings-list [companyData]="dataCompany" [pendingList]="pendingsClient"
              (refresh)="getPendings(prospectId)"></app-pendings-list>
          </div>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</div>

<div id="tabla-clientes" hidden>
  <table>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Telefono</th>
        <th>Correo</th>
        <th>Comentarios</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of company_clients">
        <td>{{item.nombre}} </td>
        <td>{{item.telefono}} </td>
        <td>{{item.email}} </td>
        <td>{{item.comments}} </td>
      </tr>
    </tbody>
  </table>
</div>
