import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { CompanyFocusService } from "../../services/companyOnFocus/company-focus.service";
import { Observable, Subscription } from "rxjs";
import { ContactService } from "../../services/contact/contact.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { CompanyService } from "../../services/company/company.service";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGrigPlugin from "@fullcalendar/timegrid";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { EventInput } from "@fullcalendar/core";
import listPlugin from "@fullcalendar/list";
import { InComingCallService } from "../../services/inComingCall/in-coming-call.service";
import { MatDialog } from "@angular/material/dialog";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import { Task } from '../../models/task';
import { ServiceMinutesService } from "src/app/services/service-minutes/service-minutes.service";
import { Company } from "src/app/models/company";
import { Contact } from "src/app/models/contact";
import { EventsService } from "src/app/services/events/events.service";
import { Event } from "src/app/services/events/model";
import { ContractsService } from "src/app/services/contracts/contracts.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProspectsService } from "src/app/services/prospects/prospects.service";
import { Prospect } from "src/app/models/prospects";
import { Pending } from "src/app/models/pending";

@Component({
  selector: "app-diary",
  templateUrl: "./diary.component.html",
  styleUrls: ["./diary.component.scss"],
})
export class DiaryComponent implements OnInit {

  @ViewChild("calendar", { static: false })
  @Output() refresh = new EventEmitter<string>();
  calendarComponent: FullCalendarComponent;
  calendarPlugins = [
    dayGridPlugin,
    timeGrigPlugin,
    interactionPlugin,
    listPlugin,
  ];
  calendarEvents: EventInput[];

  diary$: Observable<any>;
  contact$: any;
  contactInfoForm: FormGroup;
  isOnCall: boolean;
  selected_companyData = [];

  name_company: any;
  encargado_company: any; //1
  logo_company: any;
  telefono_company: any; //2/1
  email_company: any; //2
  direccion_company: any; //2/2
  giroComercial_company: any; //3
  referencias_company: any; //2/3
  contracted_plan: any;
  recurring_tasks: any;
  companyId: string = "";
  subscription: Subscription;
  public contactInfo: any = []
  public contactSub$: any
  editableContact: boolean = false;
  unreadMsg$: Observable<any>;
  readMsg$: Observable<any>;
  callStatus: any;
  driveUrl: any;
  //NEW PROPS
  events: Array<any> = [];
  private startDate: string;
  private endDate: string;
  options: any;
  public term = ''
  static companyIdFocus: string;
  public actionTitle: string;

  public pendingsClient: Pending[];
  public company_clients = [];
  @ViewChild("htmlDoc") html;
  public showInfo: boolean = false;
  public editContactId: any
  public userType: any
  public initTime: any
  public callEnd: boolean = false;
  public taskList: Task[] = []
  public dataCompany: Prospect;
  public loader = false
  public contractInfo: any

  public prospectId: any

  constructor(
    private companyService: CompanyService,
    private contactService: ContactService,
    private eventService: EventsService,
    private focusService: CompanyFocusService,
    private taskService: ServiceMinutesService,
    private InComingCallService: InComingCallService,
    private formBuilder: FormBuilder,
    private contractService: ContractsService,
    private route: ActivatedRoute,
    private prospectService: ProspectsService,
    private router: Router
  ) {
    this.callStatus = localStorage.getItem("isCallOnFocus");
    this.contactInfoForm = this.formBuilder.group({
      name: [""],
      email: [""],
      phone: [""],
      comments: [""]
    });
    DiaryComponent.companyIdFocus = this.focusService.getCompanyOnFocus();
  }

  async ngOnInit() {
    this.loader = true
    this.userType = localStorage.getItem("currentUser");
    this.prospectId = this.route.snapshot.paramMap.get('id') ?? ''
    if (this.prospectId != '') {
      this.dataCompany = await this.prospectService.getProspectByUid(this.prospectId);
      await this.getCurrentUser(this.prospectId)
      // this.pendingsClient = await this.getPendings( this.prospectId)
      await this.getPendings(this.prospectId)
      this.taskList = await this.getTasks();
      var contactList = await this.getContacts(this.prospectId);
      this.contactInfo = await this.getContract(this.prospectId)
      await this.focusService.setContactsOfCompany(contactList)
      await this.getEvents(this.prospectId);
    } else {
      this.router.navigate(['dashboard'])
    }
    this.loader = false
    // let companyId = this.focusService.getCompanyOnFocus();
    // this.companyId = this.focusService.getCompanyOnFocus();
    // this.dataCompany = await this.companyService.getCompanyByUserUid(companyId);
    // await this.focusService.setCompanyInfo(this.dataCompany);
  }

  async getPendings(companyId: string) {
    // console.log('getPendings');
    this.pendingsClient = [];
    try {
      var response = await this.companyService.getCompanyPendings(companyId);
      response.map((pending) => {
        pending.date = new Date(pending.date._seconds * 1000)
        if (pending.datefinal != undefined || pending.datefinal != null) {
          pending.datefinal = new Date(pending.datefinal._seconds * 1000)
        } if (pending.dateFinal) {
          pending.dateFinal = new Date(pending.dateFinal._seconds * 1000)
        }
        if (pending.createdDate) {
          pending.createdDate = new Date(pending.createdDate._seconds * 1000)
        }
        pending.limitTime = new Date(pending.limitTime._seconds * 1000)
        if (pending.isVisibleInApp != false) {
          pending["isVisibleInApp"] = true;
        }
      })
      this.pendingsClient = response as Pending[];
      // console.log('Nuevo length de pendientes', this.pendingsClient.length);
      // return response as Pending[];
    } catch (error) {
      console.log(error);
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperado al consultar los pendientes',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getEvents(companyId: string) {
    try {
      const events: Event[] = await this.eventService.getEvents(companyId)
      this.events = events
    } catch (error) {
      console.log(error);
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperado',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getTasks(): Promise<Task[]> {
    try {
      return await this.taskService.getTask()
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperado',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getContacts(id: string): Promise<Contact[]> {
    try {
      return await this.contactService.getContacsByCompany(id);
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperado',
        text: "Si el problema persiste, favor de reportar el error",
      })
    }
  }

  async closeCall() {
    try {
      var data = {
        name: this.contactInfo.nombre,
        email: this.contactInfo.email,
        phone: this.contactInfo.telefono,
        comments: this.contactInfo.comments || "",
        callDate: this.contactInfo.callDate
      }
      await this.contactService.updateContact(this.contactInfo.id, data);
    } catch (error) {
      console.log(error);
    }
    this.InComingCallService.deleteICC(localStorage.getItem("iCCId"));
    this.callStatus = false;
    this.callEnd = true;
    setTimeout(() => {
      this.callEnd = false;
    }, 2500);
    this.ngOnInit()
  }

  ngOnDestroy() {
  }

  async getCurrentUser(companyId) {
    try {
      let phoneContact = await this.focusService.getContact().phone;
      phoneContact ? (this.isOnCall = true) : (this.isOnCall = false);
      this.contactInfo = await this.contactService.getContact(parseInt(phoneContact), companyId);
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error inesperado, intentalo de nuevo mas tarde",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      });
    }
  }

  getCombineDateTime(date, time) {
    var hour = parseInt(time.hour);
    var min = parseInt(time.minute);
    var newDate = DateTime.fromISO(date.toISOString().split("T")[0])
      .set({ hour: hour })
      .set({ minute: min })
      .set({ second: 0 })
      .toJSDate();
    return newDate;
  }

  editContactInfo(flag) {
    if (flag) {
      this.editableContact = true;
      this.contactInfoForm.patchValue({
        name: this.contactInfo.nombre,
        email: this.contactInfo.email,
        phone: this.contactInfo.telefono,
        comments: this.contactInfo.comments
      });
    } else {
      this.editableContact = false;
    }
  }

  async saveNewInfoContact() {
    this.focusService.setContact({
      nombre: this.contactInfoForm.value.name,
      email: this.contactInfoForm.value.email,
      numero_origen: this.contactInfoForm.value.phone,
    });
    try {
      await this.contactService.updateContact(this.contactInfo.id, this.contactInfoForm.value);
      this.contactInfo.nombre = this.contactInfoForm.value.name;
      this.contactInfo.email = this.contactInfoForm.value.email;
      this.contactInfo.telefono = this.contactInfoForm.value.phone;
      this.contactInfo.comments = this.contactInfoForm.value.comments;
      this.editableContact = false;
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error inesperado, intentalo de nuevo mas tarde",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      });
    }
  }

  public async createSpaces() {
    try {
      if (!this.startDate && !this.endDate) {
        return Swal.fire({
          text: "Selecciona un rango primero",
          icon: "warning",
        });
      }

      Swal.fire({
        title: "How old are you?",
        icon: "question",
        input: "range",
        inputAttributes: {
          min: "15",
          max: "45",
          step: "1",
        },
      });

    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error inesperado, intentalo de nuevo mas tarde",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      });
    }
  }

  public selectEvents(select: any) {
    this.startDate = select.startStr;
    this.endDate = select.endStr;
  }

  async getContract(id: string) {
    try {
      const response = await this.contractService.find(id)
      return response.data
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error inesperado, al consultar la información del contrato, intentalo de nuevo mas tarde",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      });
    }
  }

}
