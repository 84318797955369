import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/users/user.service';
import { formatDate } from '@angular/common';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { Task } from 'src/app/models/task';
import { UserData } from 'src/app/models/user';
import { logClient } from 'src/app/models/logs';
import { isArray } from 'rxjs/internal-compatibility';
import { MatDialogRef } from '@angular/material';
import { Call } from 'src/app/models/call';
import { ActionsClient } from 'src/app/models/report-agent';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { CallsReportsService } from 'src/app/services/calls-reports/calls-reports.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { Prospect } from 'src/app/models/prospects';

@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss']
})
export class GenerateReportComponent implements OnInit {

  public users: any = []
  public addForm: FormGroup;
  public minDate: Date;
  public loading = false;
  public downloanding: string;
  public currentUser: string
  private extraTime: number = 0;
  private logsReports = []
  private callsReport: Call[] = []
  private callsOutReport: Call[] = []
  constructor(
    public ref: MatDialogRef<GenerateReportComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private usersService: UserService,
    private reportService: ReporteService,
    private service: AuthService,
    private prospectService: ProspectsService,
    private callService: CallsReportsService,
    private companyService: CompanyService
  ) { }

  async ngOnInit() {
    this.addForm = this.formBuilder.group({
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      agente: ["", Validators.required],
    });
    this.currentUser = localStorage.getItem("currentUser")
    if (this.currentUser == "administrador") {
      this.users = await this.getAgents()
    } else {
      this.users = await this.getCurrentUser();
    }
  }

  async getAgents() {
    try {
      return await this.usersService.getActiveUsers();
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener los datos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  async getCurrentUser() {
    try {
      let user = this.service.currentuser();
      return await this.usersService.getUserById(user.uid);
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener los datos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  async getCalls(start: any, end: any) {
    try {
      start = new Date(start).getTime().toString()
      end = new Date(end).getTime().toString()
      const response = await this.callService.getAllCalls('%20', start, end)
      return response;
    } catch (error) {
      this.loading = false;
      this.ref.close();
      document.removeEventListener('click', enableClick, true)
      if (error.error.status == 504) {
        Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }

  async getCallsOut(start: any, end: any) {
    try {
      start = new Date(start).getTime()
      end = new Date(end).getTime()
      const response = await this.callService.getOutgoinCalls('%20', start, end)
      return response;
    } catch (error) {
      this.loading = false;
      this.ref.close();
      document.removeEventListener('click', enableClick, true)
      if (error.error.status == 504) {
        Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }

  onSelectedStartDate(event) {
    var date = new Date(event);
    date.setDate(date.getDate() + 1);
    this.minDate = date;
  }

  async submit() {
    const form = this.addForm.value;
    if (form.start_date == "" || form.end_date == "") {
      this.ref.close();
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Seleccione una rango de fecha para crear el reporte',
        showConfirmButton: true,
      })
      return
    } else {
      this.loading = true
      document.addEventListener('click', enableClick, true);
      if (this.currentUser != "administrador") {
        form.agente = this.users.nombre
      }
      this.logsReports = await this.getLogsCompanies(form.start_date, form.end_date)
      this.callsReport = await this.getCalls(form.start_date.toString(), form.end_date.toString())
      this.callsOutReport = await this.getCallsOut(form.start_date.toString(), form.end_date.toString())
      this.createReport(form);
    }
  }

  private async createReport(form) {
    try {
      this.loading = true
      this.downloanding = "Consultando empresas..."
      var reportData: any = [];
      var time_start_date = form.start_date.getTime();
      var time_end_date = form.end_date.getTime();
      var day_start = formatDate(new Date(time_start_date), 'dd', 'en-US');
      var month_start = formatDate(new Date(time_start_date), 'MMMM', 'es-MX');
      var year_start = formatDate(new Date(time_start_date), 'yyy', 'en-US');
      const start_date_txt = day_start + ' de ' + month_start + " de " + year_start;

      var day_end = formatDate(new Date(time_end_date), 'dd', 'en-US');
      var month_end = formatDate(new Date(time_end_date), 'MMMM', 'es-MX');
      var year_end = formatDate(new Date(time_end_date), 'yyy', 'en-US');
      const end_date_txt = day_end + ' de ' + month_end + " de " + year_end;
      if (form.agente == "all") {
        for await (const user of this.users) {
          form.agente = user.nombre
          reportData = await this.findDataInProspects(form.agente)
          var response = await this.reportService.addAgentReport({
            report: 'Reporte del ' + start_date_txt + ' al ' + end_date_txt,
            date: new Date(),
            format_start_date: start_date_txt,
            format_end_date: end_date_txt,
            clients: reportData,
            agente: form.agente
          })
        }
        document.removeEventListener('click', enableClick, true)
        this.ref.close();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Se han creado todos los reportes",
          showConfirmButton: true,
        });
        this.router.navigate(['/reporte-agentes'])
      } else {
        reportData = await this.findDataInProspects(form.agente)
        var response = await this.reportService.addAgentReport({
          report: 'Reporte del ' + start_date_txt + ' al ' + end_date_txt,
          date: new Date(),
          format_start_date: start_date_txt,
          format_end_date: end_date_txt,
          clients: reportData,
          agente: form.agente
        })
        if (response.code == 200) {
          this.loading = false;
          this.ref.close();
          document.removeEventListener('click', enableClick, true)
          this.router.navigate(['/reporte-agentes-details/' + response.data])
        }
      }
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.ref.close();
      document.removeEventListener('click', enableClick, true)
      if (error.error.status == 504) {
        Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }

  async getLogsCompanies(startDate: any, endDate: any): Promise<Array<{ logs: logClient[], prospect: Prospect }>> {
    try {
      var reportData = []
      const prospectList = await this.prospectService.getActiveProspects()
      for await (const prospect of prospectList.data) {
        const response = await this.companyService.getLogsByRange(startDate, endDate, prospect.uid)
        reportData.push({ logs: response, prospect: prospect })
      }
      return reportData
    } catch (error) {
      throw error;
    }
  }

  async findDataInProspects(userName: string) {
    try {
      this.loading = true;
      let userSelect: UserData;
      if (isArray(this.users)) {
        userSelect = this.users.filter(userInfo => userInfo.nombre == userName)[0];
      } else {
        userSelect = this.users
      }
      var arrayOfCompanies: ActionsClient[] = []
      for await (const empresa of this.logsReports) {
        this.downloanding = "Consultando llamadas de la empresa..."
        const prospectInfo = empresa.prospect as Prospect
        let phone = prospectInfo.telefono.toString();
        let indexof52 = phone.indexOf("52");
        let phoneSearch = phone.includes("52") ? indexof52 > 0 ? `52${phone}` : phone : `52${phone}`;

        let answeredEntriesCalls = this.callsReport.filter(
          (call) => call.state == "ANSWERED" && call.did == phoneSearch && call.extension == userSelect.extension.toString()
        );
        let answeredSalientesCalls = this.callsOutReport.filter(
          (call) => call.extension == userSelect.extension.toString() && call.destino.startsWith(prospectInfo.prefix)
        );
        answeredSalientesCalls = answeredSalientesCalls.filter((call) => call.state == "ANSWERED" || call.state == 'NO ANSWER')

        //Addiotional numbers report
        if (prospectInfo.additionalPrefix != undefined) {
          for await (const iterator of prospectInfo.additionalPrefix) {
            const element = iterator.phone;
            let phoneAdd = element.toString();
            let indexof52Add = phoneAdd.indexOf("52");
            let phoneSearchAdd = phoneAdd.includes("52") ? indexof52Add > 0 ? `52${phoneAdd}` : phoneAdd : `52${phoneAdd}`;
            const additionalEntriesCalls = this.callsReport.filter(
              (call) => call.state == "ANSWERED" && call.did == phoneSearchAdd && call.extension == userSelect.extension.toString()
            );
            answeredEntriesCalls = answeredEntriesCalls.concat(additionalEntriesCalls)
            const additionalSalientesCalls = this.callsOutReport.filter(
              (call) => call.extension == userSelect.extension.toString() && call.destino.startsWith(iterator.prefix)
            );
            answeredSalientesCalls = answeredSalientesCalls.concat(additionalSalientesCalls.filter((call) => call.state == "ANSWERED" || call.state == 'NO ANSWER'))
          }
        }
        var entriesCallTime = answeredEntriesCalls.reduce((total, call) => { return total + call.duration }, 0)
        var outgoingCallTime = answeredSalientesCalls.reduce((total, call) => { return total + call.duration }, 0)
        const callTime = entriesCallTime + outgoingCallTime
        this.downloanding = "Calculando tareas..."
        const userLogs = await this.logsOfAgent(empresa.logs, userSelect);
        const tiempoTareas = (userLogs.reduce((total, log) => { return total + (log.timeTask * log.totalActions) }, 0) + this.extraTime) * 60
        const timeWorked = callTime + tiempoTareas

        var empresasUser: ActionsClient = {
          nombre_empresa: prospectInfo.nombre,
          plan: prospectInfo.plan,
          total_Llamadas: answeredEntriesCalls.length + answeredSalientesCalls.length,
          tiempo_llamadas: this.secondsToHms(callTime) || "0s",
          seconds_llamadas: callTime,
          total_tareas: userLogs.reduce((total, task) => { return total + task.totalActions }, 0),
          tiempo_tareas: this.secondsToHms(tiempoTareas) || "0s",
          seconds_tareas: tiempoTareas,
          total_trabajo: this.secondsToHms(timeWorked) || "0s",
          seconds_trabajo: timeWorked
        }
        // if (empresasUser.total_Llamadas != 0 || empresasUser.total_tareas != 0 || prospectInfo.ejecutivo.includes(userSelect.nombre) || prospectInfo.assistant.includes(userSelect.nombre)) {
        //   arrayOfCompanies.push(empresasUser);
        // }
        if (
          empresasUser.total_Llamadas != 0 ||
          empresasUser.total_tareas != 0 ||
          (prospectInfo.ejecutivo && prospectInfo.ejecutivo.includes(userSelect.nombre)) ||
          (prospectInfo.assistant && prospectInfo.assistant.includes(userSelect.nombre))
        ) {
          arrayOfCompanies.push(empresasUser);
        }
        this.extraTime = 0
      }
      return arrayOfCompanies
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async logsOfAgent(logs: logClient[], userSelect: UserData) {
    try {
      var userLogs = logs.filter(element => element.idUser == userSelect.uid || element.text.split(" por")[1] == userSelect.nombre);
      return userLogs
    } catch (error) {
      throw error
    }
  }

  async countSMEntries(entries: Array<logClient>, asingTask: Array<Task>) {
    try {
      asingTask.map(async (task) => {
        task.quantity = 0
        task.totalAll = 0
        entries.map(logs => {
          if (logs.text.startsWith(task.title)) {
            task.quantity += 1
            task.totalAll += logs.totalActions
          }
          if (logs.timeTask != undefined && logs.text.startsWith(task.title)) {
            //Se calcula el tiempo que le tomo realizar menos el tiempo real asignado a la tarea
            var time = logs.timeTask - task.minutes
            this.additionalTimeForTasks(time);
          }
        })
      })
      return asingTask
    } catch (error) {
      throw error;
    }
  }

  additionalTimeForTasks(time: number) {
    this.extraTime += time;
  }

  secondsToHms(d) {
    try {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
      return hDisplay + mDisplay + sDisplay;
    } catch (error) {
      return "0s"
    }
  }

}

function enableClick(e) {
  e.stopPropagation();
  e.preventDefault();
}
