<nb-card>
  <nb-card-header>
    <span style="float: left; text-transform: uppercase">Pendientes del día</span>
    <span style="float: right">
      <button nbButton size="small" status="warning" (click)="addPending()">
        <nb-icon icon="plus-outline"></nb-icon>Nuevo pendiente
      </button>
    </span>
  </nb-card-header>
  <nb-card-body>
    <div style="overflow: scroll; height: 80vh">
      <nb-list>
        <nb-list-item *ngFor="let log of pendingList" style="border: none">
          <div class="row" style="width: 100%">
            <div class="col-md-12" style="padding: 0">
              <nb-card style="margin: 0; border: 1px solid #ee7600">
                <nb-card-header style="text-align: right; color: grey">
                  <i><strong>Fecha: </strong>
                    {{ log.date | date: "dd"}} de
                    {{ log.date | date: "MMMM"}} del
                    {{ log.date | date: "yyyy"}} a las
                    {{ log.limitTime | date: "hh:mm a"}} </i>
                  <button nbButton size="small" (click)="hideInApp(log)"
                    [ngClass]="log.isVisibleInApp ? 'hideButton' : 'showButton'">
                    <ng-container *ngIf="log.isVisibleInApp">
                      <mat-icon>visibility_off</mat-icon> Ocultar en App
                    </ng-container>
                    <ng-container *ngIf="!log.isVisibleInApp">
                      <mat-icon>visibility</mat-icon> Mostrar en App
                    </ng-container>
                  </button>
                </nb-card-header>
                <nb-card-body>
                  <p><strong>Título: </strong> {{ log.title }}</p>
                  <p>
                    <strong>Comentarios: </strong> {{ log.comments }}
                  </p>
                  <p *ngIf="log.status == 'done'"><strong>Realizado por:</strong> {{log.whoDid}}</p>
                </nb-card-body>
                <nb-card-footer style="text-align: right;">
                  <p *ngIf="log.status == 'done'" style="color: #00d68f;"><b>Pendiente realizado</b>
                  </p>
                  <button nbButton size="small" status="success" (click)="openDialog(modalUpdate,log.id, log.title)"
                    *ngIf="log.status == 'pending'">
                    Marcar como realizado <nb-icon icon="done-all-outline"></nb-icon>
                  </button>
                </nb-card-footer>
              </nb-card>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #modalUpdate let-modal>
  <nb-card>
    <nb-card-body>
      <form [formGroup]="addForm">
        <label>Quien lo realizó</label>
        <input formControlName="whoDid" nbInput fullWidth placeholder="Ejemplo: Mi asistente" />
        <p></p>
        <div class=" form-group row">
          <div class="col-md-12">
            <label for="">Acción</label>
            <nb-select formControlName="action" fullWidth>
              <nb-option [value]="entOpt.title" *ngFor="let entOpt of taskList"
                (selectionChange)="showTimeOfTask($event.value)">
                {{entOpt.title}}
              </nb-option>
            </nb-select>
          </div>
        </div>
        <div class=" form-group row">
          <div class="col-md-12">
            <label for="">Tiempo realizado en minutos</label>
            <input formControlName="timeTask" nbInput fullWidth type="number">
          </div>
        </div>
        <div class=" form-group row">
          <div class="col-md-12">
            <label for="">Cantidad de entradas de esta acción</label>
            <input formControlName="cuota" nbInput fullWidth type="number" placeholder="Ingrese una cantidad" min="0"
              numbersOnly>
          </div>
        </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="success" (click)="submit()" style="margin-right: 6px;">Marcar como
        realizado</button>
      <button nbButton (click)="modalRef.close()">Cancelar</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
