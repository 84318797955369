<h2>Agregar archivos al prospecto</h2>
<div class=" form-group row">
    <div class="col-md-6">
        <label for="">Adjuntar contrato</label>
        <ng-container *ngIf="fileContract == undefined || fileContract == '' ">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,1)" class="fileContract"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo de contrato está vacio</span>
        </ng-container>
        <ng-container *ngIf="fileContract != '' && fileContract != undefined">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(1)"> <mat-icon>delete</mat-icon> Borrar archivo cargado
                    previamente</button>
            </div>
        </ng-container>

        <label for="">Adjuntar factura</label>
        <ng-container *ngIf="fileInvoice == undefined || fileInvoice == '' ">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,3)" class="dropFile"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo de la factura está vacio</span>
        </ng-container>
        <ng-container *ngIf="fileInvoice != '' && fileInvoice != undefined ">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(3)"> <mat-icon>delete</mat-icon> Borrar archivo cargado
                    previamente</button>
            </div>
        </ng-container>

        <label for="">Adjuntar Comprobante de pago</label>
        <ng-container *ngIf="fileFiscal == undefined || fileFiscal == '' ">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,4)" class="dropFile"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo de Comprobante de pago está vacio</span>
        </ng-container>
        <ng-container *ngIf="fileFiscal != '' && fileFiscal != undefined ">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(4)"> <mat-icon>delete</mat-icon> Borrar archivo cargado
                    previamente</button>
            </div>
        </ng-container>
    </div>
    <div class="col-md-6">
        <label for="">Adjuntar convenio de confidencialidad</label>
        <ng-container *ngIf="fileAgreement == undefined || fileAgreement == '' ">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,2)" class="dropFile"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo del convenio está vacio</span>
        </ng-container>
        <ng-container *ngIf="fileAgreement != '' && fileAgreement != undefined ">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(2)"> <mat-icon>delete</mat-icon> Borrar archivo cargado
                    previamente</button>
            </div>
        </ng-container>

        <label for="">Cedula fiscal de pago</label>
        <ng-container *ngIf="filePay == undefined || filePay== '' ">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,5)" class="dropFile"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo de Cedula fisca está vacio</span>
        </ng-container>
        <ng-container *ngIf="filePay != '' && filePay != undefined ">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(5)"> <mat-icon>delete</mat-icon> Borrar archivo cargado
                    previamente</button>
            </div>
        </ng-container>
    </div>
</div>
<div class="button-section">
    <button mat-button class="danger-btn" (click)="dialogRef.close()"><mat-icon>close</mat-icon> Cancelar</button>
    <button mat-button class="success-btn" (click)="saveFiles()"><mat-icon>add</mat-icon> Agregar archivos</button>
</div>