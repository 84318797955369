<div class="header-modal">
    <h2>{{clientInfo.nombre}}</h2>
    <button mat-button (click)="dialogRef.close()" style="border: none;"><mat-icon>close</mat-icon></button>
</div>
<div class="img-profile">
    <img [src]="clientInfo.profileImg" alt="">
</div>
<div class="row content">
    <div class="col-md-8">
        <label for="">Nombre del Cliente</label>
        <p> {{clientInfo.nombreCliente}} </p>
    </div>
    <div class="col-md-4">
        <label for="">Genero</label>
        <p> {{clientInfo.gender}} </p>
    </div>
</div>
<div class="row content">
    <div class="col-md-2">
        <label for="">Edad</label>
        <p> {{clientInfo.age}} </p>
    </div>
    <div class="col-md-3">
        <label for="">Telefono</label>
        <p> {{clientInfo.telefono}} </p>
    </div>
    <div class="col-md-7">
        <label for="">Correo</label>
        <p> {{clientInfo.email}} </p>
    </div>
</div>
<div class="row content">
    <div class="col-md-4">
        <label for="">Giro Comercial </label>
        <p> {{clientInfo.giroComercial}} </p>
    </div>
    <div class="col-md-8">
        <label for="">Dirección</label>
        <p> {{clientInfo.direccion}} </p>
    </div>

</div>
<div class="row content">
    <div class="col-md-4">
        <label for="">Ciudad</label>
        <p> {{clientInfo.city}} </p>
    </div>
    <div class="col-md-8">
        <label for="">Dirección Fiscal</label>
        <p> {{clientInfo.fiscalAddress}} </p>
    </div>

</div>
<div class="row content">
    <div class="col-md-4">
        <label for="">Ejecutivo </label>
        <p> {{clientInfo.ejecutivo}} </p>
    </div>
    <div class="col-md-8">
        <label for="">Requiere Ayuda en</label>
        <p> {{clientInfo.needHelp}} </p>
    </div>
</div>
<div class="row content">
    <div class="col-md-8">
        <label for="">Campaña </label>
        <p> {{clientInfo.campaign}} </p>
    </div>
    <div class="col-md-4">
        <label for="">Promoción</label>
        <p> {{clientInfo.promotion}} </p>
    </div>

</div>
<div class="row content">
    <div class="col-md-4">
        <label for="">Requiere Factura</label>
        <p>{{clientInfo.invoice}} </p>
    </div>
    <div class="col-md-4">
        <label for="">Especialidad</label>
        <p>{{clientInfo.specialty}} </p>
    </div>
    <div class="col-md-4">
        <label for="">Fecha inicio de Contrato</label>
        <p>{{clientInfo.contratoDate | date}} </p>
    </div>
</div>
<div class="row content">
    <div class="col-md-4">
        <label for="">Prefijo</label>
        <p>{{clientInfo.prefix}} </p>
    </div>
    <div class="col-md-4">
        <label for="">Monto a Facturar</label>
        <p>{{clientInfo.inovoicePrice}} </p>
    </div>
    <div class="col-md-4">
        <label for="">Origen</label>
        <p>{{clientInfo.origin}} </p>
    </div>
</div>
<div class="row content">
    <div class="col-md-10">
        <label for="">Drive</label>
        <p>{{clientInfo.drive}} </p>
    </div>
    <div class="col-md-2">
        <label for="">Plan</label>
        <p> {{clientInfo.plan}} </p>
    </div>
</div>
<div class="row content">
    <div class="col-md-12">
        <label for="">Tareas asignadas</label>
        <p *ngFor="let task of clientInfo.tareasRecurrentes">
            {{task.title}}
        </p>
    </div>
</div>