<section>
  <div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
  <ng-container *ngIf="userData.tipo != 'marketing'">
    <div class="grid-box">
      <div class="left-box">
        <div class="card">
          <h2>Próximos Clientes</h2>
          <div class="next-clients-row">
            <div class="prospect-card" *ngFor="let item of nextClients">
              <p><strong>Nombre:</strong> {{item.nombre}} </p>
              <p><strong>Giro:</strong> {{item.giroComercial}} </p>
              <p><strong>Tipo:</strong><span class="tag" *ngFor="let tag of item.comercialTags">{{tag}}</span> </p>
              <p><strong>Actividades</strong></p>
              <ul>
                <li>{{item.needHelp}}</li>
              </ul>
              <p><strong>Zoom:</strong> <a [href]="item.zoomLink" target="_blank"> {{item.zoomLink}}
                  <mat-icon>launch</mat-icon></a>
              </p>
              <p><strong>Fecha </strong> {{item.trainingDate | date}} </p>
              <p><strong>Hora:</strong> {{item.trainingHour}} </p>
            </div>
          </div>
        </div>
        <div class="card">
          <h2>Próximas Conferencias </h2>
          <div class="next-clients-row">
            <div class="zoom-card" *ngFor="let item of nextMeets">
              <div class="card-info">
                <p><strong>Asunto:</strong> {{item.subject}}</p>
                <p><strong>Cliente:</strong> {{item.client}}</p>
                <p><strong>Zoom:</strong> <a [href]="item.url" target="_blank"> {{item.url}}
                    <mat-icon>launch</mat-icon></a>
                </p>
                <p><strong>Fecha </strong> {{item.meetDate | date }} </p>
                <p><strong>Hora:</strong> {{item.meetDate | date:"hh:mm aa"}} </p>
              </div>
              <div class="edit-btn" (click)="updateZoom(item)">
                <mat-icon>edit</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>Mis Clientes</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="myClients.length == 0" class="empty-box">
            <p>No hay clientes asignados</p>
          </div>
          <div class="my-clients-box" *ngIf="myClients.length > 0">
            <div *ngFor="let client of myClients">
              <div class="card">
                <nb-badge
                  [text]="client.contractInfo.status == 2 ? 'El estado del contrato está pendiente por pagar' : 'El estado del contrato está atrasado'"
                  [status]="client.contractInfo.status == 2 ? 'warning': 'danger'" position="top left"
                  *ngIf="client.contractInfo.status != 1"></nb-badge>
                <nb-badge text="{{ client.totalPending }} pendientes por hacer" status="primary" position="top right"
                  *ngIf="client.totalPending > 0"></nb-badge>
                <div class="name-client">
                  <p><strong>{{client.nombre}}</strong></p>
                  <mat-icon *ngIf="client.contractInfo.status == 3" matTooltip="Actualizar contrato"
                    [routerLink]="['/clients/edit/'+client._id]">insert_drive_file</mat-icon>
                </div>
                <div class="client-body">
                  <div class="imageprofile">
                    <img [src]="client.profileImg" alt="">
                  </div>
                  <div class="actions">
                    <button nbButton status="warning" size="medium" (click)="selectCompany(client)" class="mb-1"
                      [disabled]="client.contractInfo.status != 1 && userData.tipo != 'administrador'" nbTooltip="Abrir"
                      nbTooltipStatus="warning">
                      <nb-icon icon="eye-outline"></nb-icon>
                    </button>
                    <button nbButton size="medium" status="info" (click)="showMissingCalls(client)" class="mb-1"
                      nbTooltip="Llamadas perdidas" nbTooltipStatus="info">
                      <nb-icon icon="phone-call-outline"></nb-icon>
                    </button>
                    <button nbButton size="medium" status="success" [routerLink]="['/chat/'+client.uid]" class="mb-1"
                      nbTooltip="Chat con el cliente" nbTooltipStatus="success">
                      <nb-icon icon="email-outline"></nb-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>Clientes Backup</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="backupClients.length == 0" class="empty-box">
            <p>No hay clientes asignados</p>
          </div>
          <div class="my-clients-box" *ngIf="backupClients.length != 0">
            <div *ngFor="let client of backupClients | filterClients:term">
              <div class="card">
                <nb-badge text="{{ client.totalPending }} pendientes por hacer" status="primary" position="top right"
                  *ngIf="client.totalPending > 0"></nb-badge>
                <nb-badge
                  [text]="client.contractInfo.status == 2 ? 'El estado del contrato está pendiente por pagar' : 'El estado del contrato está atrasado'"
                  [status]="client.contractInfo.status == 2 ? 'warning': 'danger'" position="top left"
                  *ngIf="client.contractInfo.status != 1"></nb-badge>
                <div class="name-client">
                  <p><strong>{{client.nombre}}</strong></p>
                  <mat-icon *ngIf="client.contractInfo.status == 3" matTooltip="Actualizar contrato"
                    [routerLink]="['/clients/edit/'+client.uid]">insert_drive_file</mat-icon>
                </div>
                <div class="client-body">
                  <div class="imageprofile">
                    <img [src]="client.profileImg" alt="">
                  </div>
                  <div class="actions">
                    <button nbButton status="warning" size="medium" (click)="selectCompany(client)" class="mb-1"
                      [disabled]="client.contractInfo.status != 1 && userData.tipo != 'administrador'" nbTooltip="Abrir"
                      nbTooltipStatus="warning">
                      <nb-icon icon="eye-outline"></nb-icon>
                    </button>
                    <button nbButton size="medium" status="info" (click)="showMissingCalls(client)" class="mb-1"
                      nbTooltip="Llamadas perdidas" nbTooltipStatus="info">
                      <nb-icon icon="phone-call-outline"></nb-icon>
                    </button>
                    <button nbButton size="medium" status="success" [routerLink]="['/chat/'+client.uid]" class="mb-1"
                      nbTooltip="Chat con el cliente" nbTooltipStatus="success">
                      <nb-icon icon="email-outline"></nb-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="all-clients-card">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2>Todos los Clientes</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="headerAll">
            <input type="text" placeholder="Buscar un cliente por nombre" [(ngModel)]="term">
          </div>
          <div class="all-clients-box">
            <div *ngFor="let client of prospectList | filterClients:term">
              <div class="card">
                <nb-badge text="{{ client.totalPending }} pendientes por hacer" status="primary" position="top right"
                  *ngIf="client.totalPending > 0"></nb-badge>
                <nb-badge
                  [text]="client.contractInfo?.status == 2 ? 'El estado del contrato está pendiente por pagar' : 'El estado del contrato está atrasado'"
                  [status]="client.contractInfo?.status == 2 ? 'warning': 'danger'" position="top left"
                  *ngIf="client.contractInfo?.status != 1"></nb-badge>
                <div class="name-client">
                  <p><strong>{{client.nombre}}</strong></p>
                  <mat-icon *ngIf="client.contractInfo.status == 3" matTooltip="Actualizar contrato"
                    [routerLink]="['/clients/edit/'+client.uid]">insert_drive_file</mat-icon>
                </div>
                <div class="client-body">
                  <div class="imageprofile">
                    <img [src]="client.profileImg" alt="">
                  </div>
                  <div class="actions">
                    <button nbButton status="warning" size="medium" (click)="selectCompany(client)" class="mb-1"
                      [disabled]="client.contractInfo.status != 1 && userData.tipo != 'administrador'" nbTooltip="Abrir"
                      nbTooltipStatus="warning">
                      <nb-icon icon="eye-outline"></nb-icon>
                    </button>
                    <button nbButton size="medium" status="info" (click)="showMissingCalls(client)" class="mb-1"
                      nbTooltip="Llamadas perdidas" nbTooltipStatus="info">
                      <nb-icon icon="phone-call-outline"></nb-icon>
                    </button>
                    <button nbButton size="medium" status="success" [routerLink]="['/chat/'+client.uid]" class="mb-1"
                      nbTooltip="Chat con el cliente" nbTooltipStatus="success">
                      <nb-icon icon="email-outline"></nb-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="userData.tipo == 'marketing'">
    <app-marketing-dashboard></app-marketing-dashboard>
  </ng-container>

</section>
