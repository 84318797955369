<nb-card>
  <nb-card-header>
    <!-- <span style="float: left; text-transform: uppercase">Pendientes del día</span> -->
    <span style="float: right" *ngIf="userType != 'administrador'">
      <button nbButton size="small" status="warning" (click)="addPending()">
        <nb-icon icon="plus-outline"></nb-icon>Nuevo pendiente
      </button>
    </span>
  </nb-card-header>
  <nb-card-body>
    <nb-tabset *ngIf="userType == 'administrador'">
      <nb-tab tabTitle="Lista de pendientes de agentes" tabIcon="list-outline">
        <div class="search">
          <mat-form-field appearance="outline">
            <mat-label>Buscar...</mat-label>
            <input type="text" matInput (keyup)="applyFilter($event)" #input>
          </mat-form-field>
          <button mat-button (click)="addPending()"><mat-icon>add</mat-icon> Nuevo pendiente</button>
        </div>
        <div class="table-container">
          <table mat-table [dataSource]="pendings" matSort (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title">
                Titulo </th>
              <td mat-cell *matCellDef="let row"> {{row.title}} </td>
            </ng-container>
            <ng-container matColumnDef="comments">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by comments">
                Comentarios </th>
              <td mat-cell *matCellDef="let row"> {{row.title}} </td>
            </ng-container>
            <ng-container matColumnDef="limitDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by limitDate"> Fecha
                limíte </th>
              <td mat-cell *matCellDef="let row"> {{row.limitTime | date:"dd MMMM yyyy hh:mm a"}} </td>
            </ng-container>
            <ng-container matColumnDef="pendingFor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by pendingFor">
                Pendiente para </th>
              <td mat-cell *matCellDef="let row"> {{row.pendingFor }} </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by pendingFor"> Estado
              </th>
              <td mat-cell *matCellDef="let row"> {{row.isDone ? 'Realizado':'No realizado' }} </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let row">
                <button mat-button (click)="deletePending(row._id)"><mat-icon
                    class="delete-btn">delete</mat-icon></button>
                <button mat-button (click)="updatePending(row)"><mat-icon class="edit-btn">edit</mat-icon></button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns:displayColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
        </div>
        <!-- <div style="overflow: scroll; height: 80vh" *ngIf="userType == 'administrador'">
                    <ng2-smart-table [settings]="settings" [source]="pendings" (create)="addPending()"
                        (edit)="updatePending($event.data)" (delete)="deletePending($event.data._id)">
                    </ng2-smart-table>
                </div> -->
      </nb-tab>
      <nb-tab tabTitle="Pendientes por concluir" tabIcon="clock-outline">
        <app-pending-time-table></app-pending-time-table>
      </nb-tab>
      <nb-tab tabTitle="Pendientes vencidos" tabIcon="slash-outline">
        <app-pending-concluded-table></app-pending-concluded-table>
      </nb-tab>
    </nb-tabset>

    <div *ngIf="userType != 'administrador'">
      <div class="table-container">
        <table mat-table #table [dataSource]="pendings" matSort (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Tarea </th>
            <td mat-cell *matCellDef="let row"> {{row.title}} </td>
          </ng-container>
          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef> Comentario </th>
            <td mat-cell *matCellDef="let row"> {{row.comments}} </td>
          </ng-container>
          <ng-container matColumnDef="limitDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by limitDate"> Fecha
              limite </th>
            <td mat-cell *matCellDef="let row">
              {{row.date | date:"dd"}} de
              {{row.date | date:"MMMM"}} del
              {{row.date | date:"yyyy"}} a las
              {{row.limitTime | date:"hh:mm a"}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Check </th>
            <td mat-cell *matCellDef="let row">
              <nb-checkbox (checkedChange)="pendingFinished(row)" [checked]="row.isDone"></nb-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let row">
              <button class="edit-btn" (click)="updatePending(row)"
                *ngIf="row.userId != 'Todos'"><mat-icon>edit</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayUserColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayUserColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
      </div>
    </div>

  </nb-card-body>
</nb-card>
